<template>

  <div v-if="props.week?.id" class="comment" @click="tg">

    <div class="ttl">
      Comments
      <span class="cnt">{{ 1 + props.week?.cnt_comments }}</span>
    </div>
    <div class="item">
      <img v-if="props.itemUser?.avatar_little" class="ava" :src="props.itemUser.avatar_little" :alt="props.itemUser.name">
      <div :class="{'text': true, 'text-ellipsis-3': true, 'is-more': isMoreButton}" ref="text">
        <div class="is-more-box">...more</div>
        {{ props.week.text ? props.week.text : '—' }}
      </div>

      <!-- <div class="date">
        {{ $dayjs(props.week.add_date).fromNow() }}
      </div> -->

    </div>
    

  </div>
  
</template>

<script setup>
  
const props = defineProps({
  week: {
    type: Object,
    required: true
  },
  itemUser: {
    type: Object,
    required: true
  },
  weekId: {
    type: Number,
    required: true
  },
  comments: {
    type: Number,
    required: false
  }
})

const text = ref(null)


const comment = computed(() => {  
  return props.comments.items_comment.filter(comment => comment.post_id === props.weekId)[0] || null
})


import { inject } from 'vue';

const actionsToggleCommentEvent = inject('actionsToggleCommentEvent');

const tg = (event) => {
  // event.stopPropagation();
  actionsToggleCommentEvent({ weekId: props.weekId });
};

const isMoreButton = computed(() => {
  if(import.meta.server) return false;
  return text.value?.clientHeight < text.value?.scrollHeight
});

</script>




<style scoped>

.comment{
  background-color: var(--un-background-color-gray);
  border-radius: 10px;
  padding: 1rem;
  cursor: pointer;
  margin-top: 0.5rem;
}

.ttl{
  font-weight: 600;
}

.cnt{
  font-size: 0.8rem;
  color: #999;
}

.item{
  display: flex;
  margin-top: 0.6rem;
  gap: 0.6rem;
  align-items: center;
}

.ava{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-bottom: auto;
  margin-top: 0;

}

.text{
  max-height: calc(1.4em* 3);
  overflow: hidden;
  position: relative;
}

.is-more-box{
  display: none;
}

.is-more .is-more-box{
  display: block;
  cursor: pointer;
  color: var(--un-color-primary);
  font-weight: bold;
  position: absolute;
  background: linear-gradient(to right, var(--un-background-color-gray-transparent-0) 0%, var(--un-background-color-gray) 40%);
  right: 0;
  bottom: -1px;
  padding-left: 2rem;
}
 
@container pb (max-width: 600px) {

  
}
</style>
