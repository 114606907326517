<template>

  <div v-if="countnutrients" class="setup" @click="toggleNutrients">

    

    <div class="ttl">
      Nutrients
      <span class="cnt">{{ countnutrients }}</span>
    </div>

    <div class="list">
      <div class="item" v-for="nutrient in (useAuth().isAuth() ? nutrients : nutrients.slice(0, 3))" :key="nutrient.id">


        <i v-if="!nutrient.id" class="ava icon-nutrient"></i> 
        <img 
          v-else-if="nutrient.avatar_little"
          class="ava"
          :src="nutrient.avatar_little" 
          :alt="nutrient.name + ' - ' + nutrient.item_brand.name" 
          >            
        <img 
          v-else-if="nutrient.item_brand && nutrient.item_brand.logo_s"
          class="ava"
          :src="nutrient.item_brand.logo_s" 
          :alt="nutrient.name + ' - ' + nutrient.item_brand.name" 
          >      

        <div class="name">
          {{ nutrient.name }}

        <span class="vl">
          {{ $convert.universal('nutrient', useAuth().getNutrient(), nutrient.val) }} 

          {{ useAuth().getNutrient() }}  
        </span>

        </div>
        
 
      </div>
    </div>


  </div>

 
  
</template>

<script setup>

import { inject } from 'vue';
  
const { t } = useI18n();
const { $constants, $convert } = useNuxtApp();

// const emits = defineEmits(['click.comment'])

const props = defineProps({
  // setup: {
  //   type: Object,
  //   required: true
  // },
  nutrients: {
    type: Array,
    required: false
  }
})


const actionsToggleNutrientsEvent = inject('actionsToggleNutrientsEvent');
const toggleNutrients = () => {
  actionsToggleNutrientsEvent({  });
};

 
const countnutrients = computed(() => {
  return props.nutrients?.length;
});
 

</script>




<style scoped>
 
 .week_comment{
  display: flex;
  gap: 5px;
  flex-direction: column;
  padding: 10px 0;
}
.date{
  font-size: 0.8rem;
  color: #999;
}

.setup{
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-x: auto;
  margin-top: 10px;
  background-color: var(--un-background-color-gray);
  border-radius: 10px;
  padding: 1rem;
  cursor: pointer;
}

.setup .ttl{
  font-weight: 600;
}
.setup .ttl .cnt{
  font-weight: normal;
}
.setup .list{
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}
.setup .list .item{
  display: flex;
  align-items: center;
  gap: 5px;
}

.setup .list .item .ava{
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.setup .list .item .name{
  font-weight: bold;
}

.setup .list .item .vl{
  font-size: 0.8rem;
  color: #999;
}

   
@container pb (min-width: 600px) {
 
  
}



</style>
